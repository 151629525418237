// card css
.card {
  border: 0;
  box-shadow: 0 0 20px 0 rgba(76, 87, 125, 0.02);
  .card-header {
    border-color: #f1f1f3;
    padding: 12px 20px;
  }
  .card-title {
    font-weight: 600;
    font-size: 18px;
    color: $black-mid;
    margin-bottom: 0px;
  }
  .card-body {
    padding: 15px 20px;
  }
}

// link css
a {
  color: $theme-color;
  // font-weight: 600;
  transition: all 0.3s ease;
  &:hover {
    color: $theme-dark-color;
  }
}

.text-black {
  color: $black;
}
.flex-1 {
  flex: 1;
}

//button css
.btn {
  font-size: 14px;
  font-weight: 400;
  border-radius: 4px;
  padding: 8px 20px;
  transition: all 0.15s ease-in-out;
  box-shadow: none;
  &:focus {
    box-shadow: none;
    outline: none;
  }
}

//status-badge

.status-badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: $success;
  color: $white;
  border-radius: 5px;
  padding: 5px 12px;
  font-size: 12px;
  line-height: normal;
  text-align: center;
  white-space: nowrap;
  min-width: 70px;
  transition: all 0.25s ease;
  &.status-active {
    color: $white;
    background-color: $success;
  }
  &.status-inactive {
    color: $white;
    background-color: $danger;
  }
  &.status-pending {
    color: $white;
    background-color: $warning;
  }
  &.status-info {
    background-color: $info;
    color: $white;
  }
  &.status-open {
    background-color: $primary;
    color: $white;
  }
  &.status-secondary {
    background-color: rgba(13, 14, 14, 0.12);
    color: $white;
  }
}

// table css
.table.custom-table {
  td,
  th {
    padding: 12px;
    vertical-align: middle;
    border-top: 1px solid #eff2f7;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #505d69;
  }
  th {
    background-color: #f8f9fa;
    border-bottom-width: 1px;
    font-weight: 700;
    color: $black-mid;
    border-color: #eff2f7;
  }
  tr {
    &:hover {
      color: #212529;
      background-color: #f5f5f5;
    }
    .name-title {
      font-size: 14px;
      font-weight: 700;
      color: $black-mid;
      min-width: 200px;
    }
    .desc-content {
      max-width: 350px;
    }
    // .message-col {
    //   max-width: 200px;
    // }
  }
  .action-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    .action-btn {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: $black-mid;
      border-radius: 5px;
      font-size: 18px;
      margin: 0px 8px;
      cursor: pointer;
      transition: all 0.25s ease;
      &:hover {
        color: $theme-color;
      }
      .feather-icon {
        width: 18px;
        height: 18px;
      }
    }
  }
}

// //editor
// .wrapperClassName {
//   .editorClassName {
//     min-height: 400px;
//     max-height: 400px;
//     overflow-y: auto;
//     border: 1px solid #f1f1f1;
//     padding: 0px 16px;
//   }
//   .toolbarClassName {
//     margin-bottom: 0px;
//   }
// }

// text editor css start
.wrapperClassName {
  .toolbarClassName {
    margin-bottom: 0px;
    border-radius: 0px;
    background: #e1e1e1;
    border: 0px;
  }
  .rdw-dropdown-wrapper,
  .rdw-option-wrapper {
    height: 34px;
    border: 1px solid #d2c8c8;
  }
  .editorClassName {
    border: 1px solid #e1e1e1;
    border-top: 0px;
    background: $white;
    min-height: 400px;
    padding: 0px 10px;
    max-height: 400px;
    font-size: 14px;

    a {
      font-weight: 400;
      color: $theme-color !important;
      text-decoration: none;
      span {
        color: $theme-color !important;
      }
      &:hover {
        color: $theme-color !important;
        text-decoration: underline;
        span {
          color: $theme-color !important;
        }
      }
    }
  }
  .rdw-image-center {
    justify-content: flex-start;
  }

  .rdw-link-modal {
    height: auto;
    right: 0px;
    left: auto;
    .rdw-link-modal-label,
    .rdw-link-modal-target-option span {
      font-size: 12px;
      font-weight: 400;
      color: $black;
      margin-bottom: 0px;
      padding: 0px;
    }
    .rdw-link-modal-input {
      border-radius: 0px;
      color: #000;
      font-size: 12px;
      height: 25px;
      border-color: #e1e1e1;
      padding: 0px 8px;
      line-height: 19px;
      display: flex;
      align-items: center;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  .rdw-link-modal-buttonsection {
    .rdw-link-modal-btn {
      background: $theme-color;
      border-color: $theme-color;
      color: #fff;
      border-radius: 4px;
      height: auto;
      padding: 4px 8px;
      font-size: 13px;

      &:hover {
        background: $theme-color;
        box-shadow: none;
      }
      &:last-child {
        background-color: #ddd;
        border: 1px solid #ddd;
        color: #000;
        &:hover {
          background-color: #ddd;
          box-shadow: none;
        }
      }
    }
  }
}
// texteditor css end

//pagination

.pagination {
  .page-item {
    .page-link {
      cursor: pointer;
    }
  }
}

.modal-style-main {
  .modal-header {
    color: #212529;
    font-size: 15px;
    font-weight: 700;
    line-height: normal;
    .close {
      position: relative;
      visibility: hidden;
    }
  }
}
.user-view-image-wrap {
  border-radius: 12px;
  position: relative;
  overflow: hidden;
  width: 104px;
  height: 104px;
  margin-right: 16px;
  img {
    position: absolute;
    top: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    object-position: center;
  }
}
.card-text {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.45;
  color: #6e6b7b;
}

// nodata start
.no-data {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  margin: 30px 0px;
  position: relative;
  min-height: 100px;
  .no-data-icon {
    font-size: 30px;
  }
  .no-data-content {
    h5 {
      color: $black-mid;
    }
  }
}
// nodata end

// sweetalert start
.swal2-container {
  font-family: "Open Sans";
  .swal2-title {
    font-size: 18px;
    font-weight: 700;
    color: $black-mid;
  }
  .swal2-content,
  .swal2-html-container {
    color: $black-mid;
    font-size: 14px;
    font-family: "Open Sans";
  }
  button.swal2-confirm {
    background: $primary !important;
    border: 1px solid $primary !important;
    box-shadow: none !important;
    color: $white;
    padding: 8px 20px;
    min-width: 90px;
    font-size: 15px;
    font-weight: 400;
    line-height: normal;
    transition: all 0.25s ease;
    border-radius: 4px;
    box-shadow: none;
  }
  button.swal2-cancel {
    margin-left: 10px;
    color: $black-mid;
    font-size: 15px;
    font-weight: 400;
    line-height: normal;
    background-color: $secondary !important;
    border: 1px solid $secondary !important;
    box-shadow: none !important;
    padding: 8px 20px;
    min-width: 90px;
    box-shadow: none;
  }
  .swal2-popup {
    max-width: 350px;
    padding: 20px 0px;
  }
  .swal2-icon {
    margin-top: 5px;
    margin-bottom: 12px;
    width: 3em;
    height: 3em;
    &::before {
      font-size: 2.5em;
    }
    .swal2-icon-content {
      font-size: 2.5em;
    }
  }
  .swal2-actions {
    margin-top: 15px;
  }
}
// sweetalert end
