body {
  // header css
  .c-header {
    border-bottom: 0px;
    min-height: 65px;
    background: $white;
    transition: all 0.45s ease 0s;
    box-shadow: 0px 0px 25px 0px rgba(45, 69, 95, 0.1);
    .brand-logo {
      padding: 2px 20px;
      img {
        width: auto;
        height: 50px;
        @media (max-width: 359px) {
          height: 44px;
        }
      }
    }

    .dropdown-menu {
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
      font-size: 13px;
      text-align: left;
      padding: 6px 4px;
      list-style: none;
      background-color: $white;
      border: 0px;
      border-radius: 0px;
      animation-name: DropDownSlide;
      animation-duration: 0.3s;
      animation-fill-mode: both;

      &.show {
        &:after {
          content: " ";
          position: absolute;
          bottom: 100%;
          right: 73px;
          border-width: 9px;
          border-style: solid;
          border-color: $transparent $transparent $white $transparent;
        }
      }
      .dropdown-divider {
        margin: 2px auto;
        width: 80%;
        border-top: 1px solid #f1f1f3;
      }
      .dropdown-header {
        color: $black-mid;
        font-weight: 700;
        font-size: 14px;
      }
      .dropdown-item {
        position: relative;
        padding: 10px 20px;
        color: $black-mid;
        font-size: 13px;
        white-space: nowrap;
        @include align-center;
        text-align: left;
        border: 0;
        transition: all 0.3s ease;
        border-radius: 2px;
        &:hover {
          color: $black-mid;
          text-decoration: none;
          background-color: #f7f9fb;
        }
        &:active,
        &.active {
          color: $white;
          background-color: $theme-color;
        }
        .feather-icon {
          width: 18px;
          height: 18px;
          font-size: 18px;
        }
      }
    }

    .c-header-nav {
      .c-header-nav-item {
        margin: 0px 5px;
        &.show {
          .c-header-nav-link {
            color: $black-mid;
            background: #eceaea;
            border-radius: 50%;
          }
        }
      }

      .c-header-nav-link {
        color: $black-mid;
        &:hover {
          color: $black-mid;
          background: #eceaea;
          border-radius: 50%;
        }
      }
      .feather-icon {
        width: 20px;
        height: 20px;
        font-size: 20px;
      }
      .header-search-bar {
        position: relative;
        display: flex;
        width: 400px;
        height: 100%;
        align-items: center;
        @media (max-width: 905px) {
          width: 263px;
        }

        .form-control {
          border: none;
          border-radius: 25px;
          height: 37px;
          padding-left: 40px;
          color: $black;
          background: #eceaea;
          align-items: center;

          &:focus {
            box-shadow: none;
            outline: none;
            border: none;
          }
        }
        .search-icon {
          position: absolute;
          left: 13px;
          top: 23px;
          width: 20px;
          height: 20px;
          font-size: 20px;
          color: #dddddd;
          @media (max-width: 767px) {
            top: 18px;
          }
        }
      }
    }

    .notification-dropdown {
      .badge {
        width: 22px;
        height: 22px;
        padding: 0px;
        @include center-item;
        font-size: 10px;
        margin-top: -26px;
      }
      .nav-link {
        svg {
          width: 22px;
          height: 22px;
          font-size: 22px;
        }
      }
      .dropdown-menu {
        min-width: 350px;
        padding: 0px;
      }
      .notification-item {
        display: flex;
        align-items: flex-start;
        padding: 0.75rem 1rem;
        cursor: pointer;
        border-bottom: 1px solid #e2e2e2;
        transition: all 0.3s ease-in-out;
        &:last-child {
          border-bottom: none;
        }
        &:hover {
          background: #f7f7f7;
          color: #181c32;
        }
        &.unread {
          background-color: #e8f2f9;
        }
        .notification-icon {
          height: 2rem;
          width: 2rem;
          margin-right: 12px;
          align-items: center;
          background-color: #556ee6;
          color: #fff;
          display: flex;
          font-weight: 500;
          justify-content: center;
          border-radius: 50%;
          img {
            width: 16px;
            filter: brightness(0) invert(1);
          }
          &.color-1 {
            background-color: #6ad8a0;
          }
          &.color-2 {
            background-color: #7e70d6;
          }
          &.color-3 {
            background-color: #bb184b;
          }
          &.color-4 {
            background-color: #6fd4de;
          }
          &.color-5 {
            background-color: #f0ca21;
          }
        }
        .notification-content {
          flex: 1 1;
          font-size: 12px;
          line-height: normal;
        }
        .notification-head {
          font-size: 15px;
          line-height: normal;
          margin-bottom: 4px;
          color: #000;
          font-weight: 600;
        }
        .notification-time {
          font-size: 12px;
          display: inline-flex;
          align-items: center;
          line-height: normal;
        }
      }
    }

    .user-dropdown {
      .user-dropdown-toggle {
        @include align-center;
        font-size: 13px;
        position: relative;
        &:hover {
          color: $black-mid;
          background: $transparent;
          border-radius: 0%;
        }
        .c-avatar {
          border-radius: 4px;
          width: auto;
          height: auto;
          color: $black-mid;
          .c-avatar-img {
            border-radius: 4px;
            width: 40px;
            height: 40px;
            margin-right: 6px;
          }
        }
        .c-avatar-info {
          padding-left: 8px;
          position: relative;
          font-size: 15px;
          color: $black-mid;
          font-weight: 700;
          font-size: 14px;
          text-align: right;
          div {
            max-width: 200px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
          @media (max-width: 575px) {
            display: none;
          }
        }
      }
    }
  }

  // sidebar css
  .c-sidebar {
    box-shadow: 10 0.75rem 1.5rem rgb(18, 38, 63, 0.03);
    background: #2a3042;
    width: 260px;

    .c-sidebar-brand {
      color: $white;
      background: $transparent;
      border-bottom: 1px solid rgba(146, 146, 146, 0.18);
      padding: 5px 20px;
      &:hover {
        text-decoration: none;
      }
    }

    &.c-sidebar-minimized {
      .c-sidebar-nav {
        margin: 0px 0px 0px 0px;
        .c-sidebar-nav-item {
          .c-sidebar-nav-link {
            padding: 12px 16px;
            border-radius: 0px;
            border-right: 0px;
            &:hover {
              @media (min-width: 992px) {
                width: 250px;
              }
            }
          }
          &:hover {
            background: transparent;
            .c-sidebar-nav-link {
              color: $white;
              background-color: #1b1b28;
              .c-sidebar-nav-icon {
                color: $white;
              }
            }
          }
        }
        .c-sidebar-nav-dropdown {
          .c-sidebar-nav-dropdown-items {
            .c-sidebar-nav-item {
              .c-sidebar-nav-link {
                padding-left: 16px;
              }
            }
          }
        }
      }
    }

    .c-sidebar-nav {
      padding-top: 20px;
    }
    .c-sidebar-nav-item {
      padding: 0px 0px;
      .c-sidebar-nav-link {
        display: flex;
        align-items: center;
        font-size: 14px;
        transition: all 0.5s ease;
        padding: 16px 16px;
        cursor: pointer;
        color: #9899ac;
        .c-sidebar-nav-icon {
          width: auto;
          font-size: 20px;
          color: #9899ac;
          width: 22px;
          height: 22px;
          fill: none;
          stroke: currentColor;
          stroke-width: 2;
          stroke-linecap: round;
          stroke-linejoin: round;
        }
        .icon-dot {
          position: relative;
          margin-right: 6px;
          &:before {
            display: block;
            width: 12px;
            height: 12px;
            content: "";
            background: transparent;
            border: 2px solid #6a7187;
            border-radius: 50%;
            position: relative;
            left: 0px;
            top: 1px;
          }
        }
        &:hover {
          color: $white;
          background: rgba(0, 0, 0, 0.25);

          .c-sidebar-nav-icon {
            color: $white;
          }
        }
        &.c-active {
          color: $white;
          transition: all 0.5s ease;
          background-color: #1b1b28;

          .c-sidebar-nav-icon {
            color: $white;
          }
        }
        &.disabled {
          pointer-events: none;
          cursor: not-allowed;
          // background: url("../assets/images/icons/lock.svg") #ddd center right
          //   no-repeat;
          background-position: 94% 11px;
          background-size: 18px;
          opacity: 0.8;
        }
      }
    }
    .c-sidebar-nav-dropdown {
      .c-sidebar-nav-dropdown-items {
        .c-sidebar-nav-item {
          .c-sidebar-nav-link {
            padding-left: 30px;
          }
        }
      }
    }
  }

  .c-main {
    padding: 30px 0px;
    .container-fluid {
      padding: 0 16px;
    }
  }

  //   breadcrumb
  .breadcrumb {
    padding: 0px;
    border-bottom: 0px;
    box-shadow: none;
    .breadcrumb-item {
      align-items: center;
      position: relative;
      &:first-child {
        padding-left: 23px;
        &:before {
          content: "";
          position: absolute;
          left: 0px;
          top: 0px;
          // background-image: url("../assets/images/icons/home-black.svg");
          background-size: 17px;
          background-repeat: no-repeat;
          width: 20px;
          height: 20px;
        }
        &:hover {
          &::before {
            // background-image: url("../assets/images/icons/home.svg");
          }
        }
      }
      a {
        font-size: 13px;
        color: $black-mid;
        &:hover {
          text-decoration: none;
          color: $theme-color;
        }
      }
      & + .breadcrumb-item {
        &:before {
          color: $black-mid;
        }
      }
      &.active {
        // color: $theme-color-gray;
        color: $theme-color;
        font-size: 13px;
      }
    }
  }
}

@keyframes DropDownSlide {
  100% {
    opacity: 1;
  }
  0% {
    opacity: 0;
  }
}

@media (min-width: 992px) and (min-width: 992px) {
  html:not([dir="rtl"])
    .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed
    ~ .c-wrapper,
  html:not([dir="rtl"])
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed
    ~ .c-wrapper {
    margin-left: 260px;
  }
}

html:not([dir="rtl"]) .c-sidebar:not(.c-sidebar-right) {
  margin-left: -260px;
}
.norification-container {
  min-width: 350px;
  padding: 0px;
  max-width: 400px;
  max-height: 500px;
  overflow-y: auto;
}
