// Here you can add other styles
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Open Sans';
  color: $black-mid;

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  .cursor-pointer {
    cursor: pointer;
  }
}

a {
  color: rgb(50, 79, 190);
}
a:hover {
  color: #283f98;
}

.info-m-list {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0px 10px;
  &.w-100 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .text-label {
    font-size: 14px;
    flex: 0 0 160px;
    max-width: 160px;
    font-weight: 700;
    color: $black-mid;
    margin-right: 10px;
    white-space: nowrap;
  }
  .text-value {
    font-size: 14px;
    font-weight: 400;
    color: $black-mid;
    flex: 1;
  }
  .icon-wrap {
    flex: 0 0 40px;
    max-width: 40px;
    color: #33d197;
  }
}
.social_icon {
  width: 37px !important;
  height: 50px !important;
}
body .c-header .notification-dropdown .notification-item {
  display: block;
  align-items: flex-start;
  padding: 0.75rem 1rem;
  white-space: normal;
  cursor: pointer;
  border-bottom: 1px solid #e2e2e2;
  transition: all 0.3s ease-in-out;
}
.c-header .notification-dropdown .notification-item .notification-head {
  font-size: 15px;
  line-height: normal;
  margin-bottom: 4px;
  color: #000;
  white-space: nowrap;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
}