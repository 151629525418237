
.admin-login-wrap{
    background-color: #ebf3fa;
    padding: 80px 16px;
}
.admin-login-card{
    padding: 32px;
    background-color: rgb(255, 255, 255);
    color: rgb(23, 43, 77);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 12%) 0px 1px 2px, rgb(0 0 0 / 5%) 0px 0px 0px 1px;
    background-image: none;
    overflow: hidden;
    margin: 0 auto;
    width: 100%;
    max-width: 480px;
    border: none;
}
.form-title{
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 16px;
    text-shadow: none;
    overflow: initial;
}
.form-content{
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.6);
}
.form-style{ 
    .form-group{
        margin-bottom: 1.25rem;
    }
    label{
        color: rgba(0, 0, 0, 0.89);
        font-family: 'Open Sans';
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        display: block;
    } 
    input{
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        width: 100%;
        background-color: #f2f9ff;
        color: #5b616a;
        font-family: Roboto, Arial, sans-serif;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        border-radius: 4px;
        height: 54px;
        border-color: #cedced;
        &:focus{
            background-color: #f2f9ff;
            border-color: #0071bb;
            box-shadow: none;
            outline: 2px solid #0071bb;
        }
    }
    .custom-button{
        font-size: 1rem;
        line-height: 0.9;
        color: white;
        background-color: #0071bb;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: 0;
        border-radius: 0.25rem;
        cursor: pointer;
        display: inline-block;
        font-weight: 600;
        margin-right: 0.5rem;
        padding: 0.75rem 1.25rem;
        text-align: center;
        text-decoration: none;
        width: 100%;
    }
    .input-icon{
        position: absolute;
        top: 40px;
        right: 10px;
        color: #222;
        display: -webkit-inline-flex;
        display: inline-flex;
        -webkit-align-items: center;
        align-items: center;
        -webkit-justify-content: center;
        justify-content: center;
        &.c-icon-custom-size{
            width: 18px;
            height: 18px;
            font-size: 18px;
        }
    }
}