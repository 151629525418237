// form css
.form-group {
  .custom-label,
  .custom-control-label {
    color: $black-mid;
    font-size: 14px;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 4px;
  }
  .form-control {
    background-color: #f5f8fa;
    border-color: #f5f8fa;
    color: $black-mid;
    padding: 10px 15px;
    height: auto;
    font-size: 14px;
    font-weight: 400;
    transition: color 0.2s ease, background-color 0.2s ease;
    &:focus {
      box-shadow: none;
      outline: none;
      border-color: #f5f8fa;
    }
    &:disabled,
    &[readonly] {
      background-color: #d8dbe0;
      opacity: 1;
    }
  }
  .input-icon {
    position: absolute;
    height: 100%;
    width: 35px;
    border-left: 1px solid #adaeae;
    right: 0px;
    top: 0px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .text-error {
    font-size: 11px;
    color: $danger;
  }
}

.phonenumber-wrapper {
  .country-select {
    flex: 0 0 85px;
    max-width: 85px;
    margin-right: 10px;
  }
  .input-div {
    flex: 1;
  }
}

.custom-radio-input {
  .custom-control-label {
    cursor: pointer;
    font-weight: 600;
    &::before {
      border: #f5f8fa solid 1px;
    }
  }
  .custom-control-input {
    &:checked {
      & ~ .custom-control-label {
        &::before {
          color: $white;
          border-color: $theme-color;
          background-color: $theme-color;
        }
      }
    }
    &:focus {
      & ~ .custom-control-label {
        &::before {
          box-shadow: none;
          border-color: #f5f8fa !important;
        }
      }
    }

    &:not(:disabled):active {
      & ~ .custom-control-label {
        &::before {
          color: $white;
          border-color: $theme-color;
          background-color: $theme-color;
        }
      }
    }
  }
}

// react select

.custom-reactselect {
  .custom-inner-reactselect__placeholder {
    font-size: 14px;
    color: #8d99aa;
    margin: 0px;
  }
  .custom-inner-reactselect__single-value {
    color: $black-mid;
    margin: 0px;
  }
  .custom-inner-reactselect__value-container {
    padding: 0px 5px 0px 0px;
  }
  .custom-inner-reactselect__control {
    min-height: 43px;
    padding-left: 15px;
    border-radius: 0.25rem;
    vertical-align: middle;
    border: 0;
    background-color: #f5f8fa;
    border-color: #f5f8fa;
    color: #212529;
    height: auto;
    font-size: 14px;
    font-weight: 400;
    transition-duration: 0.3s;
    box-shadow: none;
    &.custom-inner-reactselect__control--is-focused {
      transition-duration: 0.3s;
    }
    &.custom-inner-reactselect__control--is-disabled {
      background-color: #ddd;
      opacity: 0.7;
    }
    &:focus {
      outline: none;
      box-shadow: none;
      transition-duration: 0.3s;
    }
    &:hover {
      box-shadow: none;
      // border-color: $light_theme;
    }
  }
  .custom-inner-reactselect__indicator {
    padding: 4px;
    color: #9e9e9e;
  }
  .custom-inner-reactselect__option {
    color: $black-mid;
  }
  .custom-inner-reactselect__menu {
    z-index: 2;
    color: $black-mid;
    .custom-inner-reactselect__menu-notice--no-options {
      color: $black-mid;
    }
  }
}

.filter-field-wrap {
  display: flex;
  align-items: center;
  align-items: flex-start;
  justify-content: flex-end;
  .filter-field-tile {
    flex: 0 0 180px;
    max-width: 180px;
    padding: 0px 8px;
    &.buttons-tile {
      flex: 0 0 auto;
      max-width: unset;
    }
    .form-group {
      margin-bottom: 0px;
    }
    label {
      display: none;
    }
  }
  .filter-btn {
    height: 40px;
    width: 40px;
    min-width: auto;
    border-radius: 2px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0px;
    font-size: 16px;
    line-height: normal;
    transition: all 0.3s ease;
    &.search-btn {
      color: $white;
      background-color: $primary;
      border-color: $primary;
      &:hover {
        box-shadow: -1px 3px 12px 0px $primary;
      }
    }
    &.reset-btn {
      color: $black-mid;
      background-color: $secondary;
      border-color: $secondary;
      &:hover {
        box-shadow: -1px 3px 12px 0px $secondary;
      }
    }
    &.export-btn {
      color: $primary;
      background-color: $transparent;
      border-color: $primary;
      &:hover {
        color: $white;
        background-color: $primary;
        box-shadow: -1px 3px 12px 0px $primary;
      }
    }
  }
}

.date-filter-tile {
  flex: 1 1 275px !important;
  max-width: 275px !important;
  .DateRangePickerInput__withBorder {
    border-radius: 4px;
    padding: 3px 8px;
    background-color: #f5f8fa;
    border-color: #f5f8fa;
  }

  .CalendarDay__default {
    color: #212529;
  }
  .CalendarDay__blocked_out_of_range,
  .CalendarDay__blocked_out_of_range:active,
  .CalendarDay__blocked_out_of_range:hover {
    color: #cacccd;
  }
  .DayPicker_weekHeader {
    color: #212529;
  }
  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: $theme-color;
    border: 1px double $theme-color;
    color: #fff;
  }
  .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:hover {
    background: $theme-color;
    border: 1px double $theme-color;
    color: #fff;
  }
  .CalendarDay__selected_span {
    background: #9dacf5;
    border: 1px double $theme-color;
    color: #fff;
  }
  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover {
    background: #9dacf5;
    border: 1px double $theme-color;
    color: #484848;
  }
  .CalendarMonth_caption {
    padding-top: 16px;

    select {
      font-size: 14px;
      color: #000;
      border: 1px solid #e4e7e7;
      background: #fff;
      padding: 6px 8px;
    }
  }
  .DateInput {
    width: 92px;
  }
  .DateRangePickerInput_arrow {
    margin-right: 9px;
    .DateRangePickerInput_arrow_svg {
      fill: #b1a8a8;
      height: 18px;
      width: 18px;
    }
  }
  .DateRangePickerInput_calendarIcon {
    padding: 6px 5px;
    margin: 0px;
    .DateRangePickerInput_calendarIcon_svg {
      fill: #212529;
    }
  }
  .DateInput_input {
    padding: 7px 5px;
    color: #212529;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    background-color: #f5f8fa;
    border-color: #f5f8fa;
    color: #212529;
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #212529;
      font-weight: 500;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #212529;
      font-weight: 500;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #212529;
      font-weight: 500;
    }
  }

  .DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-right: 33px solid $theme-color;
  }
}
.transaction-report-table{
  max-height: 500px;
  overflow-y: auto;
}
