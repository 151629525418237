@mixin center-div {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
@mixin center-item {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
@mixin align-center {
  display: flex;
  align-items: center;
}
