// Variable overrides
$black: #000;
$transparent: transparent;
$white: #fff;

$primary: #4398d3;
$secondary: #ced2d8;

$success: #28c76f;
$info: #3699ff;
$warning: #ffa800;
$danger: #ea5455;
$light: #f8f9fa;
$dark: #343a40;

$theme-color: #4398d3;
$theme-dark-color: #3a8bc3;

$gray: #8b8d94;
$black-mid: #212529;
